$(document).ready(function() {

	$('body').on('submit', '.js-validate-form', function(e) {

		if (e.isDefaultPrevented()) {
			// handle the invalid form...
		} else {
			$('.js-validate-form--send').prop('disabled', true);
			var currentForm = $(this);
			var fields = $(".js-validate-form .form-control");

			$.each(fields, function(index, elem) {
				$(elem).next('.with-errors').text('');
			})

			$.ajax({
				type: "POST",
				url: $(currentForm).attr('action'),
				data: $(currentForm).serialize(),
				dataType: 'json',
				success: function(data) {
					if (data.status == "success") {

						// $('.js-validate-form .msg-success').removeClass('hidden');
						$('.js-validate-form input:not(.js-validate-form--send), .js-validate-form textarea').val("");
						$('.js-validate-form select').val($('.js-validate-form select option:first').val());

						$('.js-validate-form--send').prop('disabled', false);

						$.each(fields, function(index, elem) {
							$(elem).next('.with-errors').text('');
						})

						$(currentForm).append('<div class="js-validate-form-text-after-sending-form" style="color: #58585a; margin-top: 10px; text-align: center; font-size: 20px; font-weight: 600">' + data.text_end + '</div>');

						setTimeout(function() {
							$('.js-validate-form .msg-success').addClass('hidden');
							$('.js-validate-form-text-after-sending-form').remove();
						}, 3000);
					} else {

						var fields_db = [];
						$.each(fields, function(index, elem) {
							fields_db.push($(elem).attr('name'));
						})

						var errorsArr = Object.keys(data.errors);
						for (var i = 0; i < fields_db.length; i++) {
							for (var j = 0; j < errorsArr.length; j++) {
								if (fields_db[i].includes(errorsArr[j])) {
									$.each(fields, function(index, elem) {
										if (elem.name === fields_db[i]) {
											$(elem).parent().removeClass('has-success').addClass('has-error has-danger');
											$(elem).next('.with-errors').text(data.errors[errorsArr[j]]);
										}
									})
								} else if (errorsArr[j] == 'captcha') {
									$('.js-validate-form').find('#recaptcha').next('.with-errors').html('<p class="text-danger text-left">' + data.errors[errorsArr[j]] + '</p>');
								}
							}
						}

						$('.js-validate-form--send').prop('disabled', false);

					}
				}
			});
		}
		return false;
	});
});